@import url('https://fonts.googleapis.com/css?family=Mulish:100,200,300,400,500,600,700,800,900');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-print-color-adjust: exact;
  printer-colors: exact;
  color-adjust: exact;
}

html {
  scroll-behavior: smooth;
}
body{
  --tw-bg-opacity: 1;
  background-color: rgba(249, 249, 249, var(--tw-bg-opacity));
}

.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #f9f9f9;

  /* box-shadow: 0 1px 3px 0 #e6ebf1; */
  /* -webkit-transition: box-shadow 150ms ease; */
  /* transition: box-shadow 150ms ease; */
}

/* .StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
} */

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.table-striped tr:nth-child(even) {
  background: #f9f9f9;
}

.select-search {
  height: 46.5px;
  border-radius: 3px;
  border: 1px solid #949FB1;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  outline: none !important;
}

input[type="date"] {
    box-sizing: border-box;
    outline: 0;
    position: relative;
    width: 100%;
}
.creatableSelect :focus{
  outline: none !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.custom-datepicker-icon{
  background-image: url('./assets/icon/ico-calender.png');
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 18px;
}

.custom-downarrow{
  background-image: url('./assets/icon/ico-down-arrow.png');
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 18px;
}

.app_form input, 
.app_form select, 
.app_form textarea {
  border: 1px solid #949FB1;
  border-radius: 3px;
}

.app_form .flex-col label {
  color: #272D3C;
  font-size: 14px;

}

input:focus, 
input:focus-visible, 
input:focus-within, 
select:focus, 
select:focus-visible, 
select:focus-within, 
textarea:focus, 
textarea:focus-visible,
textarea:focus-within {
    outline: 2px solid #000000 !important;
    box-shadow: none !important;
    outline-offset: -2px !important;
}

.update-labour-rate-wrapper {
  position: relative;
  margin-left: auto;
  right: 0px;
}

.loginBox {
  width: 380px;
  max-width: 100%;
  margin: 0 auto;
}
.wktable .ant-table-content {
  overflow: auto;
}

@media screen and (max-width: 767px) {
  .mt--10
  {
    margin-top: -10px;
  }
  .for-desktop
  {
    display: none;
  }
  .update-labour-rate-wrapper
  {
    width: 100%;
    margin-top: 20px;
  }
  .main-logo 
  {
    width: 4rem;
  }
  .site-logo {
    width: 4rem;
  }
    .wk-dropdown img.w-10 {
      width: 30px;
    }
    .bg-hero {
      min-height: 220px;
    }
    .banner-text 
    {
      margin: 0 !important;
    }
  .wk-dropdown  .mt-2 {
    margin-top: 4px;
  }
  .container {
    max-width: 100%;
    padding: 0 15px;
    width: 100%;
  }
  .appData_table tbody tr {
    border-bottom: 1px solid #cccccc;
    display: flex;
    align-items: start;
    justify-content: flex-start;
    flex-wrap: wrap;    
    gap: 0;
    padding: 15px 0;
  }
  .appData_table tbody tr td {
    padding: 5px 10px;
    display: inline-flex;
    width: 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .appData_table tbody tr td.editbutton {
    position: relative;
    right: auto;
  }
  .appData_table tbody tr td.editbutton a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 34px;
    border: 1px solid #dcdcdc;
    border-radius: 2px;
}
}

.table-responsive {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  overflow-x: auto;
}
.table-responsive > table {
  width: 100%;
}
.container-inner {
  max-width: 100%;
}

option.active {
  background-color: #00a67e;
  border: none;
  color: #fff;
  font-weight: bold;
}

option.inactive {
  background-color: #ffc23da3;
  border: none;
  color: #fff;
  font-weight: bold;
}


/**********************/


.check_outer {
  display: inline-block;
  border: none;
  padding: 12px 20px;
  background-color: #fff;
}
.table_check_wrap .table-responsive {
  display: none;
}

.table_check_wrap.open .table-responsive {
  display: block;
}
.table_check_wrap.open .check_outer {
  border: 1px solid #e47756;
}


/********************/
.styled-checkbox {
  position: absolute;
  opacity: 0;
}
.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}
.styled-checkbox:checked + label:before {
  background: #f35429;
  border-color: #e47756;
}

.styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: white;
  border: 1px solid #999;
}
.styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(
45deg);

}

.loginBox .ant-select {
  padding: 0;
  width: 100% !important;
}
.loginBox .ant-select .ant-select-selector {
  border: none;
  padding: 0;
  height: auto;
}
.loginBox .ant-select .ant-select-selector .ant-select-selection-search {
  width: 100%;
    right: 0;
    left: 0;
}
.loginBox .ant-select .ant-select-selector input {
  padding: 0.75rem 1rem !important;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  height: auto !important;
  background-color: #f9f9f9 !important;
  opacity: 1 !important;
}
.loginBox .ant-select .ant-select-selection-item {
  height: auto;
  line-height: normal;
  padding: 0.75rem 1rem;
}
.d-inline-flex{
  display: inline-flex;
}
.green_row_wrap{
  display: flex;
  align-items: center;
}

.custom_scroll{
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;
}

a:hover {
  color: inherit;
}
a.text-white:hover{
  color: #ffffff;
}
a.text-primary:hover{
  color: #e47756;
}
.custom-row{
  position: relative;
}
.editbutton {
  position: absolute;
  top: 20px;
  right: 20px;
}
.report-message{
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.report-message svg{
  margin-right: 5px;
}
.disable{
  opacity: 0.6;
}
.cursor-notallowed{
  cursor: not-allowed;
}
.modal-button{
  float: right;
  padding: 8px 35px;
}
.notes{
  display: inline;
  width: 100%;
}
.read-or-hide{
  color:#808080;
  cursor: pointer;
}
.grey_text{
  color:#808080;  
}
.contentShowLess {
  max-height: 45px;
  transition: all ease .3s;
  overflow: hidden;
}
.contentShowLess.hidden-content {
  max-height: 100%;
  transition: all ease .1s;
}
.notes-editor-height{
  height: 100px;
}

.top--grid {
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  height: 100%;
  overflow: hidden;
  position: relative;
  padding: 5px 10px;
}
.top--grid .user-avatar.avatar-status img {
  border-radius: 100%;
}
.top--grid button:focus {
  outline: none;
}
.top--btn{
  display: inline-block;
}

li.ant-dropdown-menu-item-divider.visible_sml:first-child{
  display: none;
}
.ant-dropdown-menu-title-content {
  flex: auto;
  display: flex;
  align-items: center;
}
.ant-dropdown-menu-title-content .anticon {
  margin-right: 8px;
}
.text-danger {
  color: red !important
}

.ant-collapse-header .ant-collapse-arrow {
  margin-bottom: 4px;
}

.top--grid .drop--outer {
  line-height: normal;
}
.top--grid .anticon.anticon-down svg {
  font-size: 12px;
  color: #999;
}

.app_form .PhoneInput input.PhoneInputInput ,.loginBox .PhoneInput input.PhoneInputInput{
  padding-left: 48px;
}
.app_form .PhoneInput ,.loginBox .PhoneInput{
  position: relative;
}
.app_form .PhoneInput .PhoneInputCountry ,.loginBox .PhoneInput .PhoneInputCountry{
  position: absolute;
  top: 16px;
  left: 10px;
}
.app_form .PhoneInput input,.loginBox .PhoneInput input{
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  border-radius: 3px;
}
.loginBox .PhoneInput input{
  background-color: rgba(249, 249, 249, var(--tw-bg-opacity));
  margin-top: 0.25rem;
  border-radius: 0px;
}
/******************/
#components-layout-demo-custom-trigger{
  height: auto;
}
#components-layout-demo-custom-trigger .ant-table-content {
  max-height: 500px;
  overflow: auto;
}
#components-layout-demo-custom-trigger .trigger {
  padding: 0;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover,
.ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
 .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover {
  color: #F0734F;
}

#components-layout-demo-custom-trigger .logo {
  /* height: 32px; */
  margin: 16px;
}

.site-layout .site-layout-background,  .site-layout-background{
  background: #fff !important;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 10%);
 
}
.main-layout{
  border-radius: 5px;
}
#root, .ant-layout.ant-layout-has-sider {
  height: 100%;
  min-height: 100vh;
}
/*************/


.filter--wrap{
  justify-content: space-between;
}
.ant-btn-primary {
  background-color: #F0734F;
  border-color: #F0734F;
  border-radius: 4px;
  height: auto;
  padding: 6px 10px;
  box-shadow: none;
}
.ant-btn-primary:hover, .ant-btn-primary:focus {
  color: #fff;
  border-color: #F8653B;
  background: #F8653B;
}
.ant-menu-item-selected,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #F0734F;
}
.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after{
  border-color: #F0734F;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-item:active, .ant-menu-submenu-title:active {
  background-color: rgba(240,115,79,0.1);
}
.top--heading1{
align-items: center;
justify-content: space-between;
border-bottom: 1px solid #E5E7EB;
}
.filter--outer .top--heading1{
padding: 24px 24px 20px;
}

.filter--outer  .filter--wrap{
  padding: 0px 24px;
  position: relative;
  }
  .filter--outer input:focus,  .filter--outer  input:focus-visible, 
  .filter--outer input:focus-within,  .filter--outer  select:focus, 
  .filter--outer select:focus-visible,   .filter--outer select:focus-within, 
  .filter--outer textarea:focus,   .filter--outer textarea:focus-visible, 
  .filter--outer textarea:focus-within{
outline: none !important;
  }
.ant-input-search.search--wrap > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    background: #fff;
    border: 1px solid #E5E7EB;
    color: #9CA3AF;
    box-shadow: none;
}
.filter--wrap .ant-input-group-addon,.ant-input-group .ant-input{
  border-color: #E5E7EB;
}
.filter--wrap .ant-input-group .ant-input,.filter--wrap .ant-input-search-large .ant-input-search-button ,
.filter--wrap .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  max-height: 36px;
  height: 36px;
  border-radius: 4px;
  border-color: #E5E7EB;
}
.filter--wrap .ant-select-single .ant-select-selector .ant-select-selection-item{
  line-height: 34px;
}
header.ant-layout-header.site-layout-background {
  display: flex;
  justify-content: space-between;
  padding: 0px 24px;
}
/* .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #F0734F;
  border-color: #F0734F;
} */
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, 
.ant-checkbox-input:focus + .ant-checkbox-inner,.ant-checkbox-checked::after{
  border-color: #F0734F;
}

.del--icon {
  height: 36px;
  width: 36px;
  border: 1px solid #E5E7EB;
  border-radius: 4px;
  text-align: center;
  line-height: 30px;
  margin-right: 0;
  cursor: pointer;
}

.del--icon .anticon-delete {
  font-size: 18px;
  color: #9CA3AF;
}

.main--table .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active,
.main--table .ant-pagination-item-active a,.ant-pagination-item-active:hover a{
  color: #F0734F;
}
.main--table .ant-pagination-item-active,.ant-pagination-item-active:hover {
  border-color: #F0734F;
}
.main--table .ant-table-thead > tr > th{
  background-color: #F3F4FF;
}
.main--table .ant-table-thead th.ant-table-column-sort{
  background-color: rgba(243, 243, 243, 0.4);
}
.main--table .ant-table table tbody.ant-table-tbody tr:nth-child(even) {
  background-color: rgba(243, 243, 243, 0.4);
}
.main--table .ant-table-pagination.ant-pagination{
  padding: 0px 24px;
  align-items: center;
}
.main--table .ant-table-tbody > tr > td, .main--table .ant-table-thead > tr > th{
  border-bottom: 1px solid #f0f0f0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
.filter--wrap .ant-input-group-addon .anticon-search {
  font-size: 20px !important;
  color: #9CA3AF !important;
}
aside .ant-menu-inline.ant-menu-root .ant-menu-item, 
aside .ant-menu-inline.ant-menu-root .ant-menu-submenu-title{
  padding: 0px 15px !important;
}
.right--btn .ant-btn .anticon {
  font-size: 18px;
}
.right--btn .ant-btn {
  display: flex;
  align-items: center;
}
.anticon-download, .anticon-upload {
  font-size: 16px;
}
/*********/
.FormWrapper {
  padding: 24px 24px 20px;
}
#horizontal_login .ant-row.ant-form-item{
  max-width: 100%;
}
.MainLayout .ant-input,.MainLayout .ant-select-selector,
.MainLayout .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #D1D5DB; 
  min-height: 0px;
  border-radius: 3px;
  font-weight: normal;
  color: #2A3644;
}
.MainLayout .ant-select-single .ant-select-selector .ant-select-selection-item,
 .MainLayout .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
  line-height: 33px;
  padding-top: 3px;
  min-width: 100px;
}

.custom-field {

  position: relative;

}

.custom-field > input {

  visibility: hidden;

  width: 88px;

  height: 20px;
  display: none;

}

.react-datepicker__input-container {
  border: 1px solid rgb(174, 171, 171);
  margin-bottom: 2px;
  
  
}
.del--icon
{
  min-width: 36px;
}
.filter--outer .ant-btn-primary {
  display: flex;
  align-items: center;
  padding: 6px 11px;
  gap: 5px;
}
.custom-field > .placeholder {

  display: inline-flex;
  padding: 6px 11px;
  align-items: center;
  gap: 5px;


}
.w-auto 
{
  width: auto !important;
}
.ant-menu-submenu-selected {
  color: #F0734F;
}
.ant-form label{
  margin-bottom: 3px;
  display: inline-block;
}
.top--heading1 h2{
  display: flex;
  align-items: center;
}
.top--heading1 a{
  margin-right: 0px;
  line-height: 0;
}

.MainLayout .ant-table-thead > tr > th,.MainLayout .ant-table-tbody > tr > td,.MainLayout .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
  padding: 16px 24px;
}

.filter--outer .filter--wrap
{
  flex-wrap: wrap;
  gap: 10px;
}


@media(min-width: 768px){
  .d-flex{
    display: flex;
  }
  .mb-sm-0{
    margin-bottom: 0 !important;
  }
  .mt-sm-0{
    margin-top: 0 !important;
  }
}
@media(max-width: 767px){
  .dashboard_iner .ant-col {
      padding: 0 10px !important;
    }
        .text-h2-new {
          font-size: 25px;
          line-height: 29px;
        }
        .dash_col
        { 
          padding: 25px 15px !important;
          margin-bottom: 20px !important;
        }
                .dashboard_iner .ant-col {
                  padding: 0 10px !important;
                  flex: 0 0 100% !important;
                  max-width: 100%;
                }
  .input-search-box {
      width: 100% !important;
    }
  .top--heading1 button {
      padding-left: 8px;
      padding-right: 8px;
    }
        .top--heading1 h2
        {
          font-size: 17px;
        }
  .MainLayout .site-layout main.site-layout-background, .MainLayout main.site-layout-background{
    height: auto;
    line-height: normal;
  }

  .filter--outer .top--heading1 {
    padding: 15px;
  }
  .filter--outer .filter--wrap{
    padding: 0px 15px;
    margin: 10px 0px !important;
  }
  header.ant-layout-header.site-layout-background{
    padding: 0px 15px;
  }

  header.ant-layout-header.site-layout-background .ant-space-item {
    max-width: 68%;
    overflow: hidden;
    line-height: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .del--icon{
    margin-bottom: 0;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/****************/
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: rgba(227, 119, 86, var(--tw-bg-opacity));
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: rgba(227, 119, 86, var(--tw-bg-opacity));
  border-color: rgba(227, 119, 86, var(--tw-bg-opacity));
}
.ant-checkbox-checked::after{
border: 1px solid rgba(227, 119, 86, var(--tw-bg-opacity));
}

.compare--table th:nth-child(2), .compare--table td:nth-child(2), .compare--table th:nth-child(4), .compare--table td:nth-child(4) {
  background: rgba(240,115,79,0.1);
}
.compare--table th:nth-child(5), .compare--table td:nth-child(5), .compare--table th:nth-child(3), .compare--table td:nth-child(3) {
  background: rgba(255,130,0,0.15);
}
.badge {
  border-radius: 100px;
    color: #b0bac9;
    display: inline-flex;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.3;
    padding: 5px 10px;
}
.delivered {
  background-color: rgba(0, 191, 38, 0.15);
  color: #00bf26;
}
.sent {
  background-color: #00d3fe26;
  color: #00d3fe;
}
.processed {
  background-color: rgba(247, 219, 10, 0.15);
  color: orange;
}
.failed {
  background-color: rgba(244, 194, 194);
  color: red;
} 
.NA {
  background-color: rgb(245 242 242);
  color: grey;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 32px;
}
.ant-pagination-options
{
  margin-left: 9px;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: flex;
  align-items: center;
  justify-content: center;
}
.MainLayout .ant-pagination .ant-select-single:not(.ant-select-customize-input) .ant-select-selector
{
  height: 32px;
  min-height: 32px;
}
.MainLayout .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item
{
  padding-top: 0;
}
.active-inactive-filter > .ant-select-selector {
  border: none !important;
}
/* .active-inactive-filter .ant-select-selection-item {
  padding-top: 3px !important;
} */
.email-editor .ql-container {
  min-height: 10rem;
}
*:focus {
  outline: none !important;
}
.input-group {
  position: relative;
  align-items: stretch;
  margin-bottom: -15px;
}
.footer {
  background-color: #333;
  color: #fff;
  height: auto;
  font-size: 13px;
  width: 100%;
  z-index: 999;
}
.date-picker {
  line-height: 36px;
  padding-left: 18px;
}
.outer-container{
  min-height: 85vh;
}
.input-search-box {
  width: 270px;
}
.copyright {
  margin-top: 15px;
  text-align: center;
  background-color: #2d2d2d;
  border-top: 1px solid #6d6d6d;
  font-size: 13px;
  padding: 8px;
  color: #dcdcdc;
}
.btn-viewPass {
  border: none;
  margin-left: -1px;
  position: absolute;
  overflow: hidden;
  padding: 0;
  top: 95%;
  right: 40px;
  text-indent: 100px;
  white-space: nowrap;
  z-index: 1;
  outline: none !important;
}
.filter_content {
  /* position: relative; */
  z-index: 10;
  width: 100%;
  margin-left: 0;
}
.filter_content .ant-collapse{
  left: 0;
  /* position: absolute; */
  top: 0;
  width: 100%;
  background-color: transparent;
  border: none;
  box-shadow: none;
  /* position: relative; */
}


.filter_content .ant-collapse > .ant-collapse-item {
  border-bottom: none;
}
.filter_content .ant-collapse .card-body {
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
}
.filter_content .ant-collapse-content {
  background-color: #ffffff;
  border: 1px solid #eeeff1;
  border-radius: 0.125rem !important;
  box-shadow: 0 10px 20px rgb(0 0 0 / 5%);
  padding: 20px;
  position: absolute;
  left: 0;
  right: 0;
}

.filter_content .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.filter_header .title,.ant-collapse .filter_header .title {
  color: #000000;
  margin-right: auto;
  font-size: 16px;
  line-height: 1.3;
  position: relative;
  font-weight: 500;
}
.ant-collapse .filter_header .clearfilter_btn {
  background-color: rgba(0, 0, 0, 0);
  border-color: transparent;
  box-shadow: none;
  color: #333333;
  font-size: 13px;
  min-height: initial;
  height: 40px;
}
.clearfilter_btn.btn-default {
  transition: all 0.25s ease;
  align-items: center;
  border-radius: 0.125rem;
  border: 2px solid rgba(227, 119, 86, 1);
  display: inline-flex;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.5px;
  line-height: 1.3;
  padding: 5px 20px;
  position: relative;
  text-transform: uppercase;
}
.clearfilter_btn.btn-default::after {
  background-color: #ffffff;
  content: "";
  display: inline-block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: scale(0);
  transition: all 0.35s ease;
  width: 100%;
  z-index: 1;
}
.clearfilter_btn.btn-default:hover:after, .clearfilter_btn.btn-default:active:after {
  opacity: 0.35;
  transform: scale(1);
  background-color: transparent;
    border: 1px solid #333;
}
.d-flex{
  display: flex;
}
.filter_content .ant-collapse .fltr_cell {
  display: flex;
  flex-wrap: wrap;
  align-items: end;
}

.row {
   margin: 0 -15px;
    display: flex;
    flex-wrap: wrap;
}

.filter_content .ant-collapse .fltr_cell .label {
  color: #a2a2a2;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  margin: 0 0 5px;
  position: relative;
  width: 100%;
  width: 100%;
} 


.filter_content .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  align-items: center;
  background-color: #ffffff;
  border-radius: 0.125rem;
  border: 1px solid #e8e8e8;
  /* bottom: 100%; */
  box-shadow: 0 2px 9px rgb(165 165 165 / 15%);
  color: #4d4f5c;
  display: inline-flex;
  height: 36px;
  justify-content: center;
  line-height: 1.3;
  padding: 5px 20px;
  position: relative;
  /* position: absolute; */
  /* right: 164px; */
}
.w-100{
  width: 100%;
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.row > * {
  padding: 0 15px;
}

.filter_content .ant-collapse .fltr_cell .btn-primary {
  min-height: initial;
  height: 40px;
  padding: 5px 42px;
  background-color: rgba(227, 119, 86, 1);
    border: none;
    color: #ffffff;
    border-radius: 0.125rem;
}
.filter_content .ant-collapse .ant-collapse-content .ant-select .ant-select-selector {
  border: 1px solid #b0bac9 !important;
  box-shadow: none !important;
  min-height: 40px;
  height: 40px;
  border-radius: 0.125rem !important;
}
.filter--wrapper input {
  height: 38px;
}
.filter--wrapper .z-10{
  min-height: 38px;
    padding: 12px 8px;
}
.filter_content .ant-collapse .ant-collapse-content .ant-select {
  width: 100%;
}
.filter_content .ant-collapse .ant-collapse-content .ant-select.ant-select-single .ant-select-selector .ant-select-selection-item{
  line-height: 38px;
}
@media (min-width: 768px){
  .filter_content .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
}
}
@media (min-width: 992px){
  .filter_content .col-lg-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
  }
  }
@media (min-width: 1200px){
  .filter_content .col-xl-2{
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .filter_content .col-xl-3 {
  flex: 0 0 auto;
  width: 25%;
  }
}

.filter--wrapper{
  justify-content: space-between;
  position: relative;
}
/* 
.compare--table.additional-vehicle-table th:nth-child(2), .compare--table.additional-vehicle-table td:nth-child(2), .compare--table.additional-vehicle-table th:nth-child(4), .compare--table.additional-vehicle-table td:nth-child(4) {
  background: rgba(222, 194, 255,0.2);
}

.compare--table.additional-vehicle-table th:nth-child(5), .compare--table.additional-vehicle-table td:nth-child(5), .compare--table.additional-vehicle-table th:nth-child(3), .compare--table.additional-vehicle-table td:nth-child(3) {
  background: rgba(204, 211, 255,0.4);
} */

.compare--table.collision-modification-table th:nth-child(2), .compare--table.collision-modification-table td:nth-child(2), .compare--table.collision-modification-table th:nth-child(4), .compare--table.collision-modification-table td:nth-child(4) {
  background: rgba(228, 255, 246,0.3);
}
.compare--table.collision-modification-table th:nth-child(5), .compare--table.collision-modification-table td:nth-child(5), .compare--table.collision-modification-table th:nth-child(3), .compare--table.collision-modification-table td:nth-child(3) {
  
  background: rgba(176, 229, 124,0.2);
}
.input__wrap input {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  width: 100%;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  opacity: 1 !important
}
.no-match .copyright 
{
  margin:0;
}
.no-match .outer-container {
    min-height: calc(100vh - 37px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
section.bg-background.no-match {
  display: flex;
  flex-direction: column;
}
.coming-soon {
  min-height: calc(100vh - 37px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.admin-dashboard {
  border: 1px solid black;
  padding: 75px 128px;
  border-radius: 8px;
  background-color: bisque;
}

/*******************************/

.dashboard_iner {
  padding: 40px 40px 20px;
  height: 100%;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.dash_col {
  /* background: #DAEAF0; */
  padding: 45px 40px;
  border-radius: 10px;
  margin-bottom: 40px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#daeaf0+0,7db9e8+100 */
background: rgb(218,234,240); /* Old browsers */
background: -moz-linear-gradient(left,  rgba(218,234,240,1) 0%, rgba(125,185,232,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(218,234,240,1) 0%,rgba(125,185,232,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(218,234,240,1) 0%,rgba(125,185,232,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    background-size: 200% 100% ;
    position: relative;
    overflow: hidden;
}
.dash_col::before {
  content: "";
  position: absolute;
  height: 200px;
  width: 200px;
  bottom: -80px;
  right: -50px;
  border-radius: 100%;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+15,ffffff+100&0.3+21,0+100 */
background: -moz-linear-gradient(left,  rgba(255,255,255,0.3) 15%, rgba(255,255,255,0.3) 21%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(255,255,255,0.3) 15%,rgba(255,255,255,0.3) 21%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(255,255,255,0.3) 15%,rgba(255,255,255,0.3) 21%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4dffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */

}
.dash_col::after {
  content: "";
  position: absolute;
  height: 200px;
  width: 200px;
  top: -60px;
  right: 30px;
  border-radius: 100%;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+16,ffffff+100&0.2+21,0+100 */
background: -moz-linear-gradient(left,  rgba(255,255,255,0.2) 16%, rgba(255,255,255,0.2) 21%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(255,255,255,0.2) 16%,rgba(255,255,255,0.2) 21%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(255,255,255,0.2) 16%,rgba(255,255,255,0.2) 21%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */

}
.dash_col:hover{
  background-position: 100% 0 !important;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.dash_col.dash_two {
    /* background: #E3F49A; */
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e4f2a7+0,d7f25e+100 */
background: rgb(228,242,167); /* Old browsers */
background: -moz-linear-gradient(left,  rgba(228,242,167,1) 0%, rgba(215,242,94,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(228,242,167,1) 0%,rgba(215,242,94,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(228,242,167,1) 0%,rgba(215,242,94,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
background-size: 200% 100% ;
}
.dash_col.dash_three {
    /* background: #DCD2EE; */
    margin-bottom: 0;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#dcd2ee+0,a37bed+100 */
background: rgb(220,210,238); /* Old browsers */
background: -moz-linear-gradient(left,  rgba(220,210,238,1) 0%, rgba(163,123,237,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(220,210,238,1) 0%,rgba(163,123,237,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(220,210,238,1) 0%,rgba(163,123,237,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
background-size: 200% 100% ;
}
.dash_col.dash_four {
    /* background: #F8B7C4; */
    margin-bottom: 0;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f8b7c4+0,f4849a+100 */
background: rgb(248,183,196); /* Old browsers */
background: -moz-linear-gradient(left,  rgba(248,183,196,1) 0%, rgba(244,132,154,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(248,183,196,1) 0%,rgba(244,132,154,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(248,183,196,1) 0%,rgba(244,132,154,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
background-size: 200% 100% ;
}
.dash_detail .anticon {
  background: #fff;
  height: 44px;
  width: 44px;
  font-size: 20px;
  line-height: 39px;
  border-radius: 8px;
  margin-right: 15px;
}
.dash_detail {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.dash_detail h3{
  font-size: 20px;
  color: #000;
  font-weight: 600;
}
.total_count {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.total_count h3 {
  font-size: 30px;
  font-weight: bold;
}
.total_count .anticon {
  font-size: 24px;
}
.wkContainer {
  max-width: 500px;
  margin: 0 auto;
}
.close-icon .ant-select-selection-item-remove {
  display: none; 
}
.email-subject .ant-table-tbody>tr>td:nth-child(2) {
  word-break: break-word;
}

.user-profile {
  width: 40px;
  height: 40px; 
  display: block;
  border-radius: 50px;
  background: #f0f2f5;
  overflow: hidden;
}

.user-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.position-relative{
  position: relative;
}

button.btn.bg-transparent.cross_btn {
  position: absolute;
  right: 10px;
  width: 18px;
  bottom: 0;
  top: 0;
  opacity: 0.3;
  margin: auto;
}

.margin_0 .ant-collapse-header{
  margin: 0 !important;
}

.table-header-tooltip {
  display: inherit;
  margin-left: 5px;
}

.p-r-40{
  padding-right: 40px !important;
}


.radio-item-active  {
  accent-color: green ;
}
.radio-item-inactive  {
  accent-color: red;
}


.change-color{
  background: #eee !important;
}

.select-car {
  margin-bottom: 1.25rem;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  font-weight: 400;
}

.ant-table-thead > tr > th {
  background: #eee !important;
}

.forgot-link {
  display: inline-block;
  margin-right: auto;
}

 .email-logs {
  margin-top: 10px  ;
}
.dflex
{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.header-section {
  background: rgb(0 0 0 / 86%);
  position: fixed;
  z-index: 9;
  top: 0;
}
.bg-black{
  background: #000;
}

.text-h2-new
{
font-size: 30px;
text-shadow: 2px 3px 4px black;
}

.bg-hero {
  background-position: center 20%;
}


.ant-modal-body 
{
  display: "flex"; 
  justify-content: "center";
  align-items: "center";
}

.react-datepicker__navigation {
  top: 17px !important;
}
.align-center{
  align-items: center;
}
.mr-15{
  margin-right: 9px;
} 

.filter_content .ant-collapse-header-text {
  display: flex;
  align-items: center;
}
.loginBox input[type="email"] , .loginBox input[type="password"] ,  .loginBox input[type="text"] {
  width: 100%;
}

.sidebar-left .anticon-close-circle {
  display: none;
  right: 10px;
  position: absolute;
  top: -15px; 

}  
@media print{
  .print-block{
  display: block;
  }
  }
@media screen and (max-width:1023px)
{
  .header-section {
      position: sticky !important;
      display: inline-block;
      width: 100%;
      padding: 0.75rem;
    }
    .mt--10 {
      margin-top: -10px;
    }
    .mt--10 .container 
  {
    padding-top: 0 !important;
    padding-left: 15px;
    padding-bottom: 0 !important;
  }
}

@media screen and (max-width:767px)
{
  .mt--0 
  {
    margin-top: 0 !important;
  }
  
  header a.ant-dropdown-trigger
  {
    display: flex;
  }
  .sidebar-left.ant-layout-sider-collapsed .anticon-close-circle
  {
    display: none;
  }
.sidebar-left .anticon-close-circle {
      display: block;
    }
  .sidebar-left {
      position: fixed;
      z-index: 99;
    }
        .sidebar-left.ant-layout-sider-collapsed
        {
          display: none;
        }
  .top--grid
  {
    padding: 5px;
  }
    .FormWrapper {
      padding: 0;
    }
  .gridSec 
  {
    grid-auto-flow: unset;
        gap: 10px;
  }
    .header .ant-dropdown-trigger {
      display: flex;
    }
} 
@media screen and (max-width:567px)
{
  .ant-pagination-item , .ant-pagination-prev,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next
  {
    margin-right: 5px; 
  }
    li.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon {
      display: none;
    }
  .griD-one {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}